<template>
<div class="header">
    <div class="inner header-inner">
        <div class="header-logo" @click="toTop">
            <figure class="logo">
                <svg id="三角関係" xmlns="http://www.w3.org/2000/svg" width="32" height="32.369" viewBox="0 0 32 32.369">
                <path id="パス_1" data-name="パス 1" d="M214.761,5.822a2.911,2.911,0,1,0-2.911-2.911A2.91,2.91,0,0,0,214.761,5.822Z" transform="translate(-198.678)" fill="#fff"/>
                <path id="パス_2" data-name="パス 2" d="M194.98,113.546h3.934a4.591,4.591,0,0,0-.1-3.858c-.588-1.024-1.966-1.65-3.837-1.65s-3.249.625-3.838,1.65a4.591,4.591,0,0,0-.1,3.858Z" transform="translate(-178.839 -101.225)" fill="#fff"/>
                <path id="パス_3" data-name="パス 3" d="M27.016,323.747a2.911,2.911,0,1,0-2.912-2.911A2.911,2.911,0,0,0,27.016,323.747Z" transform="translate(-22.748 -297.881)" fill="#fff"/>
                <path id="パス_4" data-name="パス 4" d="M7.247,425.973c-1.871,0-3.249.625-3.838,1.648a4.591,4.591,0,0,0-.1,3.859h7.869a4.592,4.592,0,0,0-.1-3.859C10.5,426.6,9.118,425.973,7.247,425.973Z" transform="translate(-2.92 -399.111)" fill="#fff"/>
                <path id="パス_5" data-name="パス 5" d="M398.012,323.747a2.911,2.911,0,1,0-2.911-2.911A2.911,2.911,0,0,0,398.012,323.747Z" transform="translate(-370.398 -297.881)" fill="#fff"/>
                <path id="パス_6" data-name="パス 6" d="M382.081,427.621c-.587-1.023-1.966-1.648-3.837-1.648s-3.25.625-3.837,1.648a4.591,4.591,0,0,0-.1,3.859h7.868A4.59,4.59,0,0,0,382.081,427.621Z" transform="translate(-350.571 -399.111)" fill="#fff"/>
                <path id="パス_7" data-name="パス 7" d="M158.362,232.339v-7.185h-1.246v7.184l-6.173,3.565-.049.029.028.049.567.982.029.049.049-.028,6.172-3.564,6.173,3.565.049.028.029-.049.567-.982.028-.049-.049-.029Z" transform="translate(-141.625 -211.022)" fill="#fff"/>
                </svg>
            </figure>
            <h1 class="header-title">あやおり子の連絡帳</h1>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'DefaultHeader',
  props: {
    msg: String
  },
  methods: {
    toTop(e){
        this.$emit("onClick", e)
    },
   }
}
</script>