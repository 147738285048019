<template>
<div class="footer">
    <div class="inner footer-inner">
        <p class="copy">@ayaoriko 2022</p>
    </div>
</div>
</template>

<script>
export default {
  name: 'DefaultFooter',
  props: {
    msg: String
  }
}
</script>